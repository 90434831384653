/*/
    Display Flex
    -------------

    https://css-tricks.com/snippets/css/a-guide-to-flexbox/

/*/

.display.flex
{
    display: flex;
}

/*/ flex-direction /*/

.display.flex.row
{
    flex-direction: row;
}

.display.flex.column
{
    flex-direction: column;
}

.display.flex.row-reverse
{
    flex-direction: row-reverse;
}

.display.flex.column-reverse
{
    flex-direction: column-reverse;
}

/*/ flex-wrap /*/

.display.flex.wrap
{
    flex-wrap: wrap;
}

.display.flex.wrap-reverse
{
    flex-wrap: wrap-reverse;
}

.display.flex.nowrap
{
    flex-wrap: nowrap;
}

/*/ flex-flow /*/

.display.flex.row.nowrap
{
    flex-flow: row nowrap;
}

.display.flex.row-reverse.nowrap
{
    flex-flow: row-reverse nowrap;
}

.display.flex.column.nowrap
{
    flex-flow: column nowrap;
}

.display.flex.column-reverse.nowrap
{
    flex-flow: column-reverse nowrap;
}

.display.flex.row.wrap
{
    flex-flow: row wrap;
}

.display.flex.row-reverse.wrap
{
    flex-flow: row-reverse wrap;
}

.display.flex.column.wrap
{
    flex-flow: column wrap;
}

.display.flex.column-reverse.wrap
{
    flex-flow: column-reverse wrap;
}

/*/ justify-content /*/

.display.flex.justify-flex-start
{
    justify-content: flex-start;
}

.display.flex.justify-flex-end
{
    justify-content: flex-end;
}

.display.flex.justify-center
{
    justify-content: center;
}

.display.flex.justify-space-between
{
    justify-content: space-between;
}

.display.flex.justify-space-around
{
    justify-content: space-around;
}

.display.flex.justify-space-evenly
{
    justify-content: space-evenly;
}

.display.flex.justify-start
{
    justify-content: start;
}

.display.flex.justify-end
{
    justify-content: end;
}

.display.flex.justify-left
{
    justify-content: left;
}

.display.flex.justify-right
{
    justify-content: right;
}

/*/ align-items /*/

.display.flex.align-center
{
    align-items: center;
}

.display.flex.align-flex-start
{
    align-items: flex-start;
}

.display.flex.align-flex-end
{
    align-items: flex-end;
}

.display.flex.align-flex-stretch
{
    align-items: stretch;
}

.display.flex.align-baseline
{
    align-items: baseline;
}

/*/ align-content /*/

.display.flex.align-content-normal
{
    align-content: normal;
}

.display.flex.align-content-flex-start
{
    align-content: flex-start;
}

.display.flex.align-content-flex-end
{
    align-content: flex-end;
}

.display.flex.align-content-center
{
    align-content: center;
}

.display.flex.align-content-space-between
{
    align-content: space-between;
}

.display.flex.align-content-space-around
{
    align-content: space-around;
}

.display.flex.align-content-space-evenly
{
    align-content: space-evenly;
}

.display.flex.align-content-stretch
{
    align-content: stretch;
}

.display.flex.flex-1 > *
{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

/*/ Prefix /*/
@mixin flexbox()
{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values)
{
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}

@mixin order($val)
{
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

.display.flex
{
    @include flexbox();
}
