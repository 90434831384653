/*/ Bootstrap /*/
@import '~bootstrap/scss/bootstrap';
/*/ Fontawesome /*/
@import '~@fortawesome/fontawesome-free/css/all.css';
/*/ Fonts /*/
@import 'fonts';
/*/ Animations /*/
@import 'anim';
/*/ Flexbox /*/
@import 'flex';
/*/ Mixins /*/
@import 'mixins';
/*/ Design tokens /*/
@import 'design-tokens/cold_vars';
@import 'design-tokens/hot_vars';

*
{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

html
{
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--line-height);

    height: 100%;
    background-color: #ddd;
}

body
{
    @include background-pattern('square-versatiles');

    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--line-height);

    min-height: 100%;
    position: relative;

    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
        "page page page page page page page page page page page page page page page page";
}

@import 'preloader';
@import 'link';
@import 'video';

body > .ui.grid
{
    grid-area: page;
    background-color: transparent;
    position: relative;
}

.hidden
{
    display: none !important;
}

h1
{
    font-family: var(--font-header-xl-family);
    font-weight: var(--font-header-xl-weight);
    font-size: var(--font-header-xl-size);
}

h2
{
    font-family: var(--font-header-l-family);
    font-weight: var(--font-header-l-weight);
    font-size: var(--font-header-l-size);
}

h3, h4, h5, h6
{
    font-family: var(--font-header-m-family);
    font-weight: var(--font-header-m-weight);
    font-size: var(--font-header-m-size);
}

p
{
    font-family: var(--font-paragraph-m-family);
    font-weight: var(--font-paragraph-m-weight);
    font-size: var(--font-paragraph-m-size);
}

img
{
    max-width: 100%;
}

figure
{
    max-width: 80vw;
    width: 100%;
    margin: 0;
}

.no-scroll
{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scroll::-webkit-scrollbar
{
    display: none; /* Chrome, Safari and Opera */
}

/*/
Top Navigation
/*/
.segment.top-nav
{
    --background-color: black;
    --foreground-color: #eee;

    position: relative;
    height: $topnav-height;
    padding: $spacer-2 $spacer-3;
    background-color: var(--background-color);

    @media only screen and (min-width: $desktop-breakpoint)
    {
        height: $topnav-height;
        padding: 0em $spacer-5;
        margin-top: 0;
        margin-bottom: $topnav-height;
        display: flex;
        justify-content: space-between;
        background-color: var(--background-color);
    }
}

.segment.top-nav ul
{
    @media only screen and (max-width: $desktop-breakpoint)
    {
        width: 100%;
        background-color: var(--background-color);
        z-index: 1030;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    @media only screen and (min-width: $desktop-breakpoint)
    {
        height: 100%;
        list-style: none;
        display: flex;
    }
}

.segment.top-nav ul.hidden
{
    @media only screen and (min-width: $desktop-breakpoint)
    {
        display: flex !important;
    }
}

.segment.top-nav ul.right
{
    @media only screen and (max-width: $desktop-breakpoint)
    {
        height: $topnav-right-menu-height;
        border-top: 1px groove #333;
    }
}

.segment.top-nav ul.left
{
    @media only screen and (max-width: $desktop-breakpoint)
    {
        top: calc(#{$topnav-height} + #{$topnav-right-menu-height});
        height: calc(100vh - #{$topnav-height} - #{$topnav-right-menu-height});
    }
}

.segment.top-nav ul li
{
    height: 100%;
    margin: 0em;

    @media only screen and (max-width: $desktop-breakpoint)
    {
        height: $topnav-right-menu-item-height;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: $spacer-3;
        padding-right: $spacer-3;
    }
}

.segment.top-nav ul li a
{
    height: 100%;
    padding: $spacer-2 $spacer-2;
    text-decoration: none;
    color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all .2s ease;

    @media only screen and (max-width: $desktop-breakpoint)
    {
        width: 100%;
        justify-content: left;
        text-align: left;
    }
}

.segment.top-nav ul li a:hover
{
    background-color: #333;
    border-bottom: 2px solid #eee;
}

.segment.top-nav .logo-wrapper
{
    position: absolute;
    top: 0;
    left: 0;
    height: 90%;
    padding: $spacer-3 $spacer-3;

    .global-link
    {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: transparent;
    }

    @media only screen and (min-width: $desktop-breakpoint)
    {
        position: relative;
        top: 0;
        left: 0;
        transform: translateX(-50%);
        margin: $spacer-1 auto;
        height: $topnav-height-desktop;
        background: transparent;
        z-index: 1;
    }
}

.segment.top-nav .global-link:hover ~ .logo
{
    @media only screen and (min-width: $desktop-breakpoint)
    {
        transform: translateY(4px);
    }
}

.segment.top-nav .logo
{
    max-height: 100%;
    border-radius: 50%;
    object-fit: contain;
    transition: transform .052s ease;
}

.segment.top-nav .logo:hover
{
    @media only screen and (min-width: $desktop-breakpoint)
    {
        transform: translateY(4px);
    }
}

.segment.top-nav .tagline
{
    display: none;

    @media only screen and (min-width: $desktop-breakpoint)
    {
        display: inline-block;
        position: absolute;
        left: 100%;
        bottom: 0;
        min-width: 30vw;
        color: #333;
    }
}

.navicon
{
    position: absolute;
    top: 0;
    right: 0;
    margin: $spacer-3 $spacer-3;
    cursor: pointer;
    font-size: 1.4rem;
    padding-right: $spacer-3;
    color: var(--foreground-color);

    @media only screen and (min-width: $desktop-breakpoint)
    {
        display: none;
    }
}

/*/ About /*/
.segment.about
{
    padding: $spacer-3 $spacer-2;
    max-width: $desktop-breakpoint;
    margin: $spacer-2 auto;
    background-color: #eee;

    @media only screen and (min-width: $desktop-breakpoint)
    {
        padding: $spacer-3 $spacer-5;
        border-radius: var(--border-radius-m);
    }
}

/*/ Contact /*/
.segment.contact
{
    padding: $spacer-3 $spacer-2;
    width: 100%;
    margin: $spacer-2 auto;
    background-color: #eee;

    @media only screen and (min-width: $tablet-breakpoint)
    {
        max-width: $tablet-breakpoint;
        border-radius: var(--border-radius-m);
    }

    @media only screen and (min-width: $desktop-breakpoint)
    {
        max-width: $desktop-breakpoint;
        padding: $spacer-3 $spacer-5;
    }
}

.form.contact
{
    width: 100%;
    margin: $spacer-3 auto;
    padding: $spacer-2 $spacer-3;
}

.form.contact input,
.form.contact textarea
{
    width: 100%;
    border-radius: var(--border-radius-s);
}

.segment.forgot-password,
.segment.register form,
.segment.login form
{
    width: 100%;
    max-width: 96vw;
    margin: $spacer-5 auto;
    padding: $spacer-5 $spacer-2;
    border-radius: var(--border-radius-m);
    background-color: #eee;

    @media only screen and (min-width: $mobile-breakpoint)
    {
        max-width: 360px;
        margin: $spacer-5 auto;
        padding: $spacer-5 $spacer-5;
    }
}

.segment.forgot-password .item,
.segment.login form .item,
.segment.register form .item
{
    display: flex;
    flex-direction: column;
    margin: $spacer-3 auto;
}

.segment.register form > h2,
.segment.login form > h2
{
    text-align: center;
}

.footer-area
{
    position: relative;
    padding: $spacer-3 $spacer-2;

    @media only screen and (min-width: $desktop-breakpoint)
    {
        width: 80%;
        max-width: $desktop-breakpoint;
        margin: 0em auto;
        padding: $spacer-3 $spacer-5;
    }
}

.footer-area .sections {
    display: flex;
    justify-content: space-between;
}

.footer-area .section
{
    margin: $spacer-3 $spacer-3;
}

.footer-area .section ul
{
    list-style: none;
}

.footer-area .section ul li
{
    margin: $spacer-2 $spacer-2;
}

.footer-area .section ul li a
{
    text-decoration: none;
    color: #333;
}

.footer-area .section ul li a:hover
{
    opacity: .8;
}

.footer-area .brand
{
    text-align: center;
}

.footer-area .copyright
{
    color: #444;
    border-top: 1px solid #d2d6de;
    text-align: center;
    font-size: 10px;
    height: $main-footer-height;
}

.footer-area .section .social {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
}
