/*/
Links and Button links
-----------------------

https://css-tricks.com/css-basics-styling-links-like-boss/
https://css-tricks.com/a-complete-guide-to-links-and-buttons/
https://www.bitdegree.org/learn/css-link-color

http://nunforest.com/kinetic/?storefront=envato-elements
http://preview.hasthemes.com/pixal-preview/pixal/restaurant.html
http://preview.hasthemes.com/pixal-preview/index.html?storefront=envato-elements
/*/

@import 'design-tokens/cold_vars';
@import 'design-tokens/hot_vars';

a.link,
a.button-link
{
    /*/ Primary /*/
    --color-primary-light: #266dd3;
    --color-primary-lighter: #266dd3;
    --color-primary-main: #266dd3;
    --color-primary-dark: #266dd3;
    --color-primary-darker: #266dd3;
    /*/ Secondary /*/
    --color-secondary-light: #E8A87C;
    --color-secondary-lighter: #E8A87C;
    --color-secondary-main: #E8A87C;
    --color-secondary-dark: #E8A87C;
    --color-secondary-darker: #E8A87C;
    /*/ Accent /*/
    --color-accent-light: #E27D60;
    --color-accent-lighter: #E27D60;
    --color-accent-main: #E27D60;
    --color-accent-dark: #E27D60;
    --color-accent-darker: #E27D60;
    /*/ Success /*/
    --color-success-light: #41B3A3;
    --color-success-lighter: #41B3A3;
    --color-success-main: #41B3A3;
    --color-success-dark: #41B3A3;
    --color-success-darker: #41B3A3;
    /*/ Info /*/
    --color-info-light: #34b4df;
    --color-info-lighter: #41cefd;
    --color-info-main: #2e99bd;
    --color-info-dark: #257792;
    --color-info-darker: #1f6075;
    /*/ Warning /*/
    --color-warning-light: #db9200;
    --color-warning-lighter: #FFA900;
    --color-warning-main: #b37701;
    --color-warning-dark: #8f5f00;
    --color-warning-darker: #614101;
    /*/ Danger /*/
    --color-danger-light: #d6304c;
    --color-danger-lighter: #F93154;
    --color-danger-main: #a31621; /*/ ruby red /*/ // #bd263f;
    --color-danger-dark: #8f1d30;
    --color-danger-darker: #5c1420;
    /*/ Others /*/
    --color-lighter: #FFFFFF;
    --color-light: #e2e1e1;
    --color-dark: #030303;
    --color-darker: #011627; /*/ rich black fogra /*/
    /*/ Background /*/
    --color-background-light: #f7f7ff;
    --color-background-lighter: #f0f3f5; /*/ cultured /*/
    --color-background-main: #ffffff;
    --color-background-dark: #fdfffc; /*/ baby powder /*/
    --color-background-darker: #fcf7f8; /*/ snow /*/
    /*/ Foreground /*/
    --color-foreground-light: #07020D; /*/ Xiketic /*/
    --color-foreground-lighter: #24272b;
    --color-foreground-main: #000000;
    --color-foreground-dark: #1d201f; /*/ eerie black /*/
    --color-foreground-darker: #111111;
    /*/ Border color /*/
    --color-border-color-main: #f7f7f7;

    --button-link-padding: 1em 1.5em;
    --button-link-border-radius: 0.22em;
    --button-link-hover-color: #555;
    --button-link-active-color: #555;
    --button-link-visited-color: #ccc;
}

/*/ Links /*/
a.link
{
    background-color: transparent;
    color: var(--color-foreground-main);
    text-decoration: none;
}

a.link.primary
{
    color: var(--color-primary-main);
}

a.link.accent
{
    color: var(--color-accent-main);
}

a.link.secondary
{
    color: var(--color-secondary-main);
}

a.link.danger
{
    color: var(--color-danger-main);
}

a.link.success
{
    color: var(--color-success-main);
}

a.link.warning
{
    color: var(--color-warning-main);
}

a.link.info
{
    color: var(--color-info-main);
}

a.link.light
{
    color: var(--color-foreground-light);
}

a.link.dark
{
    color: var(--color-foreground-dark);
}

a:visited
{
    opacity: .97;
}

a:hover
{
    text-decoration: underline;
    cursor: pointer;
}

a:active
{
    color: var(--link);
}

a:focus
{
    text-decoration: none;
}

a.link:focus
{
    opacity: .65;
}

/*/ Button links /*/
a.button-link
{
    position: relative;
    padding: $spacer-1 $spacer-2;
    margin: $spacer-1 $spacer-2;
    text-transform: none;
    text-decoration: none;
    color: var(--color-lighter);
    background-color: var(--color-primary-main);
    border-radius: var(--button-link-border-radius);
    display: inline-block;
    max-width:max-content;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a.button-link.primary
{
    background-color: var(--color-primary-main);
}

a.button-link.accent
{
    background-color: var(--color-accent-main);
}

a.button-link.secondary
{
    background-color: var(--color-secondary-main);
}

a.button-link.danger
{
    background-color: var(--color-danger-main);
}

a.button-link.success
{
    background-color: var(--color-success-main);
}

a.button-link.warning
{
    background-color: var(--color-warning-main);
}

a.button-link.info
{
    background-color: var(--color-info-main);
}

a.button-link.light
{
    background-color: var(--color-lighter);
    color: var(--black);
}

a.button-link.dark
{
    background-color: var(--color-dark);
}

/*/ raised button-link /*/
a.raised.button-link.primary
{
    box-shadow: 0 5px 0 var(--color-primary-dark);
}

a.raised.button-link.accent
{
    box-shadow: 0 5px 0 var(--color-accent-dark);
}

a.raised.button-link.secondary
{
    box-shadow: 0 5px var(--color-secondary-dark);
}

a.raised.button-link.danger
{
    box-shadow: 0 5px 0 var(--color-danger-dark);
}

a.raised.button-link.success
{
    box-shadow: 0 5px 0 var(--color-success-dark);
}

a.raised.button-link.warning
{
    box-shadow: 0 5px 0 var(--color-warning-dark);
}

a.raised.button-link.info
{
    box-shadow: 0 5px 0 var(--color-info-dark);
}

a.raised.button-link.light
{
    box-shadow: 0 5px 0 var(--color-light);
}

a.raised.button-link.dark
{
    box-shadow: 0 5px 0 var(--color-darker);
}
/*/ end raised button-link /*/

a.button-link:hover
{
    text-decoration: none;
    opacity: .75;
}

a.button-link:active
{
    opacity: .85;
}

/*/ raised button-link:active /*/
a.raised.button-link:active
{
    box-shadow: none;
    top: 5px;
}
/*/ end raised button-link:active /*/

a.button-link:visited
{
    opacity: .97;
}

/*/ Loading /*/

/* Specificity hack */
a.button-link.loading.loading.loading.loading.loading.loading
{
    position: relative;
    cursor: wait;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: none;
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;
}

a.button-link.loading:before
{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0em 0em -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.15);
}

a.loading.button-link:after
{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0em 0em -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    -webkit-animation: button-spin 0.6s linear;
            animation: button-spin 0.6s linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #FFFFFF transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    -webkit-box-shadow: 0px 0px 0px 1px transparent;
            box-shadow: 0px 0px 0px 1px transparent;
}

@-webkit-keyframes button-spin
{
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

@keyframes button-spin
{
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

/*/ Disabled /*/
a.button-link.disabled,
a.button-link:disabled,
a.button-link.disabled:hover
{
    cursor: not-allowed;
    opacity: 0.45 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    pointer-events: none !important;
}
