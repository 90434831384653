.responsive-video
{
    overflow: hidden;
    position: relative;
    width: 100%;
}

.responsive-video::after
{
    padding-top: 56.2493%;
    display: block;
    content: '';
}

.responsive-video iframe
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
